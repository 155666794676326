import { HiArrowLongLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

import f from "../../assets/opticalFiberForCeilings/f.jpg";
import i from "../../assets/TeleBackground/i.jpg";
import h from "../../assets/RecipEntrance/h.jpg";
import b from "../../assets/Barkieh/b.jpg";
import a from "../../assets/3D_wallPaper/a.jpg";
import d from "../../assets/millWorkWall/d.jpg";
import e from "../../assets/newDiskDrawerAndWardrobe/e.jpg";
import g from "../../assets/outDoorSubstituteWood/g.jpg";
import c from "../../assets/GypsumBoard/c.jpg";
import j from "../../assets/wallSoundInsulation/j.jpg";
import k from "../../assets/inDoorSubstituteWood/k.jpg";
import l from "../../assets/marbleAlternative/l.jpg";
import m from "../../assets/bedAndDrawerBackground/m.jpg";

const Services = () => {
  return (
    <div className="my-10">
      <motion.div
        className="flex flex-col items-center justify-center"
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{
          delay: 0.7,
          y: {
            type: "spring",
            stiffness: 60,
          },
          opacity: { duration: 1 },
          ease: "easeIn",
          duration: 0.5,
        }}
      >
        <h1 className="font-bold text-4xl">خدماتنا</h1>
        <span className="h-2 w-20 mt-1 bg-gradienthref-tr from-lightBlue to-green-300 rounded-full"></span>
      </motion.div>
      <div className=" py-10 grid grid-cols-1 md:grid-cols-2 justify-items-center  lg:grid-cols-4 gap-4 lg:gap-8">
        <Link to="خلفيات-اسرة-ودواليب">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.2,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 0.4,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover"
              src={m}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                خلفيات اسرة ودواليب{" "}
              </h1>
              <p>نقوم بعمل خلفيات للاسرة والدواليب بتصميمات حديثة و عصرية </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="خزائن-ودواليب">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ x: -30, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.2,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.5 },
              ease: "easeIn",
              duration: 0.2,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={e}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                خزائن ودواليب
              </h1>
              <p>
                ديكور الخزائن والدواليب يعد جزءًا أساسيًا من تصميم الديكور
                الداخلي لأي مساحة، سواء كانت غرفة معيشة، غرفة نوم، مكتب، أو حتى
                مطبخ.{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="خلفيات-تلفزيون">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={i}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                خلفيات التلفزيون
              </h1>
              <p>
                ديكور الخلفيات التلفزيونية يعتبر عنصرًا أساسيًا في إنشاء جو
                مناسب وجذاب للمشاهدين أثناء مشاهدة البرامج التلفزيونية.{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="مداخل-استقبال">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ x: -30, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.2,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.5 },
              ease: "easeIn",
              duration: 0.2,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={h}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                مداخل الاستقبال
              </h1>
              <p>
                {" "}
                تُعتبر مداخل الاستقبال بمثابة البوابة الأولى التي يمر بها
                الزائرين والضيوف، وتعكس بشكل كبير هوية وطابع المكان.
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="ارضيات-خشبية-باركيه">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.2,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.5 },
              ease: "easeIn",
              duration: 0.2,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={b}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                الارضيات الخشبية
              </h1>
              <p>
                خيارًا شائعًا وجذابًا في تصميم الداخلي للعديد من المنازل
                والمباني التجارية، نظرًا لمظهره الجمالي والدافئ ولمميزاته
                الوظيفية.
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="ورق-جدران-ثلاثية-الابعاد">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={a}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                ورق جدران 3D
              </h1>
              <p>
                ورق الجدران ثلاثي الأبعاد (3D) هو تطور مثير في عالم تصميم
                الديكور الداخلي، حيث يُضفي أبعادًا إضافية وعمقًا على الجدران
                ويُحدث تأثيرًا بصريًا مذهلاً.{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="براويز-فوم">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={d}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                براويز فوم
              </h1>
              <p>
                براويز الفوم أو الإطارات الفومية هي خيار شائع وجميل لتزيين
                الجدران وعرض الصور واللوحات بشكل جذاب.{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>

        <Link to="الياف-ضوئية-روز">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.2,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 0.2 },
              ease: "easeIn",
              duration: 0.4,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover"
              src={f}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                ألياف ضوئية ( روز )
              </h1>
              <p>
                نقوم بعمل الألياف الضوئية لإنشاء تأثيرات ضوئية مبتكرة وجمالية في
                الأسقف
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="بديل-الخشب-خارجي">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={g}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                بديل الخشب (خارجي)
              </h1>
              <p>
                {" "}
                يكون هذا الديكور متيناً ومقاوماً للعوامل الجوية، مما يجعله
                خيارًا مثاليًا للاستخدام في الهواء الطلق.
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="جبس-بورد">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={c}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                جبس بورد
              </h1>
              <p>
                {" "}
                جبس بورد اسقف وجدران يُستخدم لإنشاء أسقف مزخرفة وجدران مُزينة
                بأشكال مختلفة.{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="بديل-الخشب-داخلي">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={k}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                {" "}
                بديل الخشب (داخلي)
              </h1>
              <p>
                بديل الخشب داخلي يشير إلى المواد التي تستخدم كبديل عن الخشب
                التقليدي في التطبيقات الداخلية. الاستدامة البيئية.
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="بديل-الرخام-مرايا">
          <motion.div
            className="relative h-96 w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg "
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.3,
              y: {
                type: "spring",
                stiffness: 60,
              },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 0.5,
            }}
          >
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={l}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                {" "}
                بديل الرخام مرايا
              </h1>
              <p> "اكتشف جمال الرخام بتكلفة أقل مع بديل الرخام الذكي!. </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </motion.div>
        </Link>
        <Link to="عوازل-اصوات-جدران-ابواب-اسقف">
          <div className="relative h- w-72 flex flex-col items-center   shadow-lg shadow-slate-400 rounded-lg ">
            <img
              className="rounded-bl-[40%] rounded-t-lg object-cover h-48 w-full"
              src={j}
              alt=""
            />
            <div className="p-4">
              <h1 className="text-2xl text-center pb-2 font-semibold">
                عوازل اصوات جدران ابواب اسقف
              </h1>
              <p>
                عوازل الأصوات للجدران والأبواب والأسقف تعتبر جزءًا هامًا من
                تصميم الديكور الداخلي، حيث تساهم في خلق بيئة هادئة .{" "}
              </p>
            </div>
            <button className="absolute bottom-0 left-0 bg-primary px-8 py-2 rounded-bl-lg rounded-tr-3xl text-white font-medium flex items-center justify-between">
              <p className="">المزيد من الصور</p>
              <span className="inline-block mr-1">
                <HiArrowLongLeft size={25} />
              </span>
            </button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Services;
