import React from 'react'
import {motion} from 'framer-motion'

const Item = ({img}) => {
  return (
    <motion.div className='flex items-center justify-center'
      initial={{y: 100, opacity: 0}}
      animate={{y: 0, opacity: 1}}
      transition={{delay: 0.6, 
        y: {
          stiffness: 60
        },
        opacity: {duration: 1},
        ease: 'easeIn',
        duration: 1,
      }}
    >
      <img src={img} className='rounded-md w-full' alt="" />
    </motion.div>


  )
}

export default Item
