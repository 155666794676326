import { Link } from "react-router-dom"
import {motion} from 'framer-motion'
const FirstItem = ({title, description}) => {
  return (
    <motion.div className="flex flex-col items-center justify-center p-4 "
    initial={{y: 50, opacity: 0}}
    whileInView={{y: 0, opacity: 1}}
    viewport={{once: true}}
    transition={{delay: 0.7, 
      y: {
        type: 'spring', stiffness: 60
      },
      opacity: {duration: 1},
      ease: 'easeIn',
      duration: 0.5,
    }}
    >
      <h1 className="text-2xl font-semibold p-2" >{title}</h1>
      <Link to='tel: +9660507276269'><h1 className="text-2xl font-semibold p-2 underline">للتواصل: 0507276269</h1></Link>
      <p className="text-md font-semibold p-2">{description}</p>
      <h1 className="text-2xl font-semibold p-2">أعمالنا:</h1>
    </motion.div>
  )
}

export default FirstItem
