//'opticalFiberForCeilings'

import f from '../assets/opticalFiberForCeilings/f.jpg'
import f1 from '../assets/opticalFiberForCeilings/f1.jpg'
import f2 from '../assets/opticalFiberForCeilings/f2.jpg'
import f3 from '../assets/opticalFiberForCeilings/f3.jpg'
import f4 from '../assets/opticalFiberForCeilings/f4.jpg'
import f5 from '../assets/opticalFiberForCeilings/f5.jpg'
import f6 from '../assets/opticalFiberForCeilings/f6.jpg'
import f7 from '../assets/opticalFiberForCeilings/f7.jpg'
import f8 from '../assets/opticalFiberForCeilings/f8.jpg'
import f9 from '../assets/opticalFiberForCeilings/f9.jpg'
import f10 from '../assets/opticalFiberForCeilings/f10.jpg'
import f11 from '../assets/opticalFiberForCeilings/f11.jpg'
import f12 from '../assets/opticalFiberForCeilings/f12.jpg'
import f13 from '../assets/opticalFiberForCeilings/f13.jpg'
import f14 from '../assets/opticalFiberForCeilings/f14.jpg'

//TeleBackground

import i from '../assets/TeleBackground/i.jpg'
import i1 from '../assets/TeleBackground/i1.jpg'
import i2 from '../assets/TeleBackground/i2.jpg'
import i3 from '../assets/TeleBackground/i3.jpg'
import i4 from '../assets/TeleBackground/i4.jpg'
import i5 from '../assets/TeleBackground/i5.jpg'
import i6 from '../assets/TeleBackground/i6.jpg'
import i7 from '../assets/TeleBackground/i7.jpg'
import i8 from '../assets/TeleBackground/i8.jpg'
import i9 from '../assets/TeleBackground/i9.jpg'
import i10 from '../assets/TeleBackground/i10.jpg'
import i11 from '../assets/TeleBackground/i11.jpg'
import i12 from '../assets/TeleBackground/i12.jpg'
import i13 from '../assets/TeleBackground/i13.jpg'
import i14 from '../assets/TeleBackground/i14.jpg'
import i15 from '../assets/TeleBackground/i15.jpg'
import i16 from '../assets/TeleBackground/i16.jpg'
import i17 from '../assets/TeleBackground/i17.jpg'
import i18 from '../assets/TeleBackground/i18.jpg'
import i19 from '../assets/TeleBackground/i19.jpg'

//RecipEntrance

import h from '../assets/RecipEntrance/h.jpg'
import h1 from '../assets/RecipEntrance/h1.jpg'
import h2 from '../assets/RecipEntrance/h2.jpg'
import h3 from '../assets/RecipEntrance/h3.jpg'
import h4 from '../assets/RecipEntrance/h4.jpg'
import h5 from '../assets/RecipEntrance/h5.jpg'
import h6 from '../assets/RecipEntrance/h6.jpg'
import h7 from '../assets/RecipEntrance/h7.jpg'
import h8 from '../assets/RecipEntrance/h8.jpg'
import h9 from '../assets/RecipEntrance/h9.jpg'
import h10 from '../assets/RecipEntrance/h10.jpg'
import h11 from '../assets/RecipEntrance/h11.jpg'
import h12 from '../assets/RecipEntrance/h12.jpg'
import h13 from '../assets/RecipEntrance/h13.jpg'
import h14 from '../assets/RecipEntrance/h14.jpg'
import h16 from '../assets/RecipEntrance/h16.jpg'
import h17 from '../assets/RecipEntrance/h17.jpg'
import h18 from '../assets/RecipEntrance/h18.jpg'

//Barkieh

import b from '../assets/Barkieh/b.jpg'
import b1 from '../assets/Barkieh/b1.jpg'
import b2 from '../assets/Barkieh/b2.jpg'
import b3 from '../assets/Barkieh/b3.jpg'
import b4 from '../assets/Barkieh/b4.jpg'
import b5 from '../assets/Barkieh/b5.jpg'
import b6 from '../assets/Barkieh/b6.jpg'
import b7 from '../assets/Barkieh/b7.jpg'
import b8 from '../assets/Barkieh/b8.jpg'
import b9 from '../assets/Barkieh/b9.jpg'
import b10 from '../assets/Barkieh/b10.jpg'
import b11 from '../assets/Barkieh/b11.jpg'
import b12 from '../assets/Barkieh/b12.jpg'
import b13 from '../assets/Barkieh/b13.jpg'
import b14 from '../assets/Barkieh/b14.jpg'
import b15 from '../assets/Barkieh/b15.jpg'
import b16 from '../assets/Barkieh/b16.jpg'
import b17 from '../assets/Barkieh/b17.jpg'
import b18 from '../assets/Barkieh/b18.jpg'
import b19 from '../assets/Barkieh/b19.jpg'

//3D_wallPaper 

import a from '../assets/3D_wallPaper/a.jpg'
import a1 from '../assets/3D_wallPaper/a1.jpg'
import a2 from '../assets/3D_wallPaper/a2.jpg'
import a3 from '../assets/3D_wallPaper/a3.jpg'
import a4 from '../assets/3D_wallPaper/a4.jpg'
import a5 from '../assets/3D_wallPaper/a5.jpg'
import a6 from '../assets/3D_wallPaper/a6.jpg'
import a7 from '../assets/3D_wallPaper/a7.jpg'
import a8 from '../assets/3D_wallPaper/a8.jpg'
import a9 from '../assets/3D_wallPaper/a9.jpg'
import a10 from '../assets/3D_wallPaper/a10.jpg'
import a11 from '../assets/3D_wallPaper/a11.jpg'
import a12 from '../assets/3D_wallPaper/a12.jpg'
import a13 from '../assets/3D_wallPaper/a13.jpg'
import a14 from '../assets/3D_wallPaper/a14.jpg'
import a15 from '../assets/3D_wallPaper/a15.jpg'
import a16 from '../assets/3D_wallPaper/a16.jpg'
import a17 from '../assets/3D_wallPaper/a17.jpg'
import a18 from '../assets/3D_wallPaper/a18.jpg'


//millWorkWall

import d from '../assets/millWorkWall/d.jpg'
import d1 from '../assets/millWorkWall/d1.jpg'
import d2 from '../assets/millWorkWall/d2.jpg'
import d3 from '../assets/millWorkWall/d3.jpg'
import d4 from '../assets/millWorkWall/d4.jpg'
import d5 from '../assets/millWorkWall/d5.jpg'
import d6 from '../assets/millWorkWall/d6.jpg'
import d7 from '../assets/millWorkWall/d7.jpg'
import d8 from '../assets/millWorkWall/d8.jpg'
import d9 from '../assets/millWorkWall/d9.jpg'
import d10 from '../assets/millWorkWall/d10.jpg'
import d11 from '../assets/millWorkWall/d11.jpg'
import d12 from '../assets/millWorkWall/d12.jpg'
import d13 from '../assets/millWorkWall/d13.jpg'
import d14 from '../assets/millWorkWall/d14.jpg'
import d15 from '../assets/millWorkWall/d15.jpg'

//newDiskDrawerAndWardrobe

import e from '../assets/newDiskDrawerAndWardrobe/e.jpg'
import e1 from '../assets/newDiskDrawerAndWardrobe/e1.jpg'
import e2 from '../assets/newDiskDrawerAndWardrobe/e2.jpg'
import e3 from '../assets/newDiskDrawerAndWardrobe/e3.jpg'
import e4 from '../assets/newDiskDrawerAndWardrobe/e4.jpg'
import e5 from '../assets/newDiskDrawerAndWardrobe/e5.jpg'

//outDoorSubstituteWood

import g from '../assets/outDoorSubstituteWood/g.jpg'
import g1 from '../assets/outDoorSubstituteWood/g1.jpg'
import g2 from '../assets/outDoorSubstituteWood/g2.jpg'
import g3 from '../assets/outDoorSubstituteWood/g3.jpg'
import g4 from '../assets/outDoorSubstituteWood/g4.jpg'
import g5 from '../assets/outDoorSubstituteWood/g5.jpg'
import g6 from '../assets/outDoorSubstituteWood/g6.jpg'
import g7 from '../assets/outDoorSubstituteWood/g7.jpg'
import g8 from '../assets/outDoorSubstituteWood/g8.jpg'
import g9 from '../assets/outDoorSubstituteWood/g9.jpg'
import g10 from '../assets/outDoorSubstituteWood/g10.jpg'

//GypsumBoard

import c from '../assets/GypsumBoard/c.jpg'
import c1 from '../assets/GypsumBoard/c1.jpg'
import c2 from '../assets/GypsumBoard/c2.jpg'
import c3 from '../assets/GypsumBoard/c3.jpg'
import c4 from '../assets/GypsumBoard/c4.jpg'
import c5 from '../assets/GypsumBoard/c5.jpg'
import c6 from '../assets/GypsumBoard/c6.jpg'
import c7 from '../assets/GypsumBoard/c7.jpg'
import c8 from '../assets/GypsumBoard/c8.jpg'
import c9 from '../assets/GypsumBoard/c9.jpg'
import c10 from '../assets/GypsumBoard/c10.jpg'
import c11 from '../assets/GypsumBoard/c11.jpg'
import c12 from '../assets/GypsumBoard/c12.jpg'
import c13 from '../assets/GypsumBoard/c13.jpg'
import c14 from '../assets/GypsumBoard/c14.jpg'
import c15 from '../assets/GypsumBoard/c15.jpg'
import c16 from '../assets/GypsumBoard/c16.jpg'
import c17 from '../assets/GypsumBoard/c17.jpg'

//wallSoundInsulation

import j from '../assets/wallSoundInsulation/j.jpg'
import j1 from '../assets/wallSoundInsulation/j1.jpg'
import j2 from '../assets/wallSoundInsulation/j2.jpg'
import j3 from '../assets/wallSoundInsulation/j3.jpg'
import j4 from '../assets/wallSoundInsulation/j4.jpg'
import j5 from '../assets/wallSoundInsulation/j5.jpg'
import j6 from '../assets/wallSoundInsulation/j6.jpg'
import j7 from '../assets/wallSoundInsulation/j7.jpg'
import j8 from '../assets/wallSoundInsulation/j8.jpg'
import j9 from '../assets/wallSoundInsulation/j9.jpg'
import j10 from '../assets/wallSoundInsulation/j10.jpg'
import j11 from '../assets/wallSoundInsulation/j11.jpg'
import j12 from '../assets/wallSoundInsulation/j12.jpg'
import j13 from '../assets/wallSoundInsulation/j13.jpg'
import j14 from '../assets/wallSoundInsulation/j14.jpg'
import j15 from '../assets/wallSoundInsulation/j15.jpg'
import j16 from '../assets/wallSoundInsulation/j16.jpg'
import j17 from '../assets/wallSoundInsulation/j17.jpg'

//inDoorSubstituteWood
 
import k from '../assets/inDoorSubstituteWood/k.jpg'
import k1 from '../assets/inDoorSubstituteWood/k1.jpg'
import k2 from '../assets/inDoorSubstituteWood/k2.jpg'
import k3 from '../assets/inDoorSubstituteWood/k3.jpg'
import k4 from '../assets/inDoorSubstituteWood/k4.jpg'
import k5 from '../assets/inDoorSubstituteWood/k5.jpg'
import k6 from '../assets/inDoorSubstituteWood/k6.jpg'


//marbleAlternative

import l from '../assets/marbleAlternative/l.jpg'
import l1 from '../assets/marbleAlternative/l1.jpg'
import l2 from '../assets/marbleAlternative/l2.jpg'
import l3 from '../assets/marbleAlternative/l3.jpg'
import l4 from '../assets/marbleAlternative/l4.jpg'

//bedAndDrawerBackground

import m from '../assets/bedAndDrawerBackground/m.jpg'
import m1 from '../assets/bedAndDrawerBackground/m1.jpg'
import m2 from '../assets/bedAndDrawerBackground/m2.jpg'
import m3 from '../assets/bedAndDrawerBackground/m3.jpg'
import m4 from '../assets/bedAndDrawerBackground/m4.jpg'
import m5 from '../assets/bedAndDrawerBackground/m5.jpg'
import m6 from '../assets/bedAndDrawerBackground/m6.jpg'
import m7 from '../assets/bedAndDrawerBackground/m7.jpg'
import m8 from '../assets/bedAndDrawerBackground/m8.jpg'
import m9 from '../assets/bedAndDrawerBackground/m9.jpg'
import m10 from '../assets/bedAndDrawerBackground/m10.jpg'
import m11 from '../assets/bedAndDrawerBackground/m11.jpg'
import m12 from '../assets/bedAndDrawerBackground/m12.jpg'
import m13 from '../assets/bedAndDrawerBackground/m13.jpg'
import m14 from '../assets/bedAndDrawerBackground/m14.jpg'
import m15 from '../assets/bedAndDrawerBackground/m15.jpg'
import m16 from '../assets/bedAndDrawerBackground/m16.jpg'
import m17 from '../assets/bedAndDrawerBackground/m17.jpg'
import m18 from '../assets/bedAndDrawerBackground/m18.jpg'
import m19 from '../assets/bedAndDrawerBackground/m19.jpg'
import m20 from '../assets/bedAndDrawerBackground/m20.jpg'
import m21 from '../assets/bedAndDrawerBackground/m21.jpg'

const all_Data =[
  {
    id: 1,
    category:'opticalFiberForCeilings' ,
    img: f 
  },
  {
    id: 2,
    category:'opticalFiberForCeilings' ,
    img: f1 
  },
  {
    id: 3,
    category:'opticalFiberForCeilings' ,
    img: f2
  },
  {
    id: 4,
    category: 'opticalFiberForCeilings' ,
    img: f3
  },
  {
    id: 5,
    category: 'opticalFiberForCeilings' ,
    img: f4
  },
  {
    id: 6,
    category: 'opticalFiberForCeilings' ,
    img: f5
  },
  {
    id: 7,
    category:'opticalFiberForCeilings' ,
    img: f6
  },
  {
    id: 8,
    category: 'opticalFiberForCeilings' ,
    img: f7
  },
  {
    id: 9,
    category:'opticalFiberForCeilings' ,
    img: f8
  },
  {
    id: 10,
    category: 'opticalFiberForCeilings',
    img: f9
  },
  {
    id: 11,
    category: 'opticalFiberForCeilings',
    img: f10
  },
  {
    id: 12,
    category: 'opticalFiberForCeilings',
    img: f11
  },
  {
    id: 13,
    category: 'opticalFiberForCeilings',
    img: f12
  },
  {
    id: 14,
    category: 'opticalFiberForCeilings',
    img: f13
  },
  {
    id: 15,
    category: 'opticalFiberForCeilings',
    img: f14
  },
  {
    id: 16,
    category: 'TeleBackground',
    img: i
  },
  {
    id: 17,
    category: 'TeleBackground',
    img: i1 
  },
  {
    id: 18,
    category: 'TeleBackground',
    img: i2 
  },
  {
    id: 19,
    category: 'TeleBackground',
    img: i3
  },
  {
    id: 20,
    category: 'TeleBackground',
    img: i4
  },
  {
    id: 21,
    category: 'TeleBackground',
    img: i5
  },
  {
    id: 22,
    category: 'TeleBackground',
    img: i6
  },
  {
    id: 23,
    category: 'TeleBackground',
    img: i7
  },
  {
    id: 24,
    category: 'TeleBackground',
    img: i8
  },
  {
    id: 25,
    category: 'TeleBackground',
    img: i9
  },
  {
    id: 26,
    category: 'TeleBackground',
    img: i10
  },
  {
    id: 27,
    category: 'TeleBackground',
    img: i11
  },
  {
    id: 28,
    category: 'TeleBackground',
    img: i12
  },
  {
    id: 29,
    category: 'TeleBackground',
    img: i13
  },
  {
    id: 30,
    category: 'TeleBackground',
    img: i14
  },
  {
    id: 31,
    category: 'TeleBackground',
    img: i15
  },
  {
    id: 32,
    category: 'TeleBackground',
    img: i16
  },
  {
    id: 33,
    category: 'TeleBackground',
    img: i17
  },
  {
    id: 34,
    category: 'TeleBackground',
    img: i18
  },
  {
    id: 35,
    category: 'TeleBackground',
    img: i19
  },
  {
    id: 36,
    category: 'RecipEntrance',
    img: h
  },
  {
    id: 37,
    category: 'RecipEntrance',
    img: h1
  },
  {
    id: 38,
    category: 'RecipEntrance',
    img: h2
  },
  {
    id: 39,
    category: 'RecipEntrance',
    img: h3
  },
  {
    id: 40,
    category: 'RecipEntrance',
    img: h4
  },
  {
    id: 41,
    category: 'RecipEntrance',
    img: h5
  },
  {
    id: 42,
    category: 'RecipEntrance',
    img: h6
  },
  {
    id: 43,
    category: 'RecipEntrance',
    img: h7
  },
  {
    id: 44,
    category: 'RecipEntrance',
    img: h8
  },
  {
    id: 45,
    category: 'RecipEntrance',
    img: h9
  },
  {
    id: 46,
    category: 'RecipEntrance',
    img: h10
  },
  {
    id: 47,
    category: 'RecipEntrance',
    img: h11
  },
  {
    id: 48,
    category: 'RecipEntrance',
    img: h12
  },
  {
    id: 49,
    category: 'RecipEntrance',
    img: h13
  },
  {
    id: 50,
    category: 'RecipEntrance',
    img: h14 
  },
  {
    id: 51,
    category: 'RecipEntrance',
    img: h16
  },
  {
    id: 52,
    category: 'RecipEntrance',
    img: h17
  },
  {
    id: 53,
    category: 'RecipEntrance',
    img: h18
  },
  {
    id: 54,
    category: 'Barkieh',
    img: b
  },
  {
    id: 55,
    category: 'Barkieh',
    img: b1
  },
  {
    id: 56,
    category: 'Barkieh',
    img: b2
  },
  {
    id: 57,
    category: 'Barkieh',
    img: b3
  },
  {
    id: 58,
    category: 'Barkieh',
    img: b4
  },
  {
    id: 59,
    category: 'Barkieh',
    img: b5
  },
  {
    id: 60,
    category: 'Barkieh',
    img: b6
  },
  {
    id: 61,
    category: 'Barkieh',
    img: b7
  },
  {
    id: 62,
    category: 'Barkieh',
    img: b8 
  },
  {
    id: 63,
    category: 'Barkieh',
    img: b9
  },
  {
    id: 64,
    category: 'Barkieh',
    img: b10
  },
  {
    id: 65,
    category: 'Barkieh',
    img: b11
  },
  {
    id: 66,
    category: 'Barkieh',
    img: b12
  },
  {
    id: 67,
    category: 'Barkieh',
    img: b13
  },
  {
    id: 68,
    category: 'Barkieh',
    img: b14
  },
  {
    id: 69,
    category: 'Barkieh',
    img: b15
  },
  {
    id: 70,
    category: 'Barkieh',
    img: b16
  },
  {
    id: 71,
    category: 'Barkieh',
    img: b17
  },
  {
    id: 72,
    category: 'Barkieh',
    img: b18
  },
  {
    id: 73,
    category: 'Barkieh',
    img: b19
  },
  {
    id: 74,
    category: '3D_wallPaper',
    img: a
  },
  {
    id: 75,
    category: '3D_wallPaper',
    img: a1
  },
  {
    id: 76,
    category: '3D_wallPaper',
    img: a2
  },
  {
    id: 77,
    category: '3D_wallPaper',
    img: a3
  },
  {
    id: 78,
    category: '3D_wallPaper',
    img: a4
  },
  {
    id: 79,
    category: '3D_wallPaper',
    img: a5
  },
  {
    id: 78,
    category: '3D_wallPaper',
    img: a6
  },
  {
    id: 79,
    category: '3D_wallPaper',
    img: a7
  },
  {
    id: 80,
    category: '3D_wallPaper',
    img: a8
  },
  {
    id: 81,
    category: '3D_wallPaper',
    img: a9
  },
  {
    id: 82,
    category: '3D_wallPaper',
    img: a10
  },
  {
    id: 83,
    category: '3D_wallPaper',
    img: a11
  },
  {
    id: 84,
    category: '3D_wallPaper',
    img: a12
  },
  {
    id: 85,
    category: '3D_wallPaper',
    img: a13
  },
  {
    id: 86,
    category: '3D_wallPaper',
    img: a14
  },
  {
    id: 87,
    category: '3D_wallPaper',
    img: a15
  },
  {
    id: 88,
    category: '3D_wallPaper',
    img: a16
  },
  {
    id: 89,
    category: '3D_wallPaper',
    img: a17
  },
  {
    id: 90,
    category: '3D_wallPaper',
    img: a18
  },
  {
    id: 91,
    category: 'millWorkWall',
    img: d
  },
  {
    id: 92,
    category: 'millWorkWall',
    img: d1
  },
  {
    id: 93,
    category: 'millWorkWall',
    img: d2
  },
  {
    id: 94,
    category: 'millWorkWall',
    img: d3
  },
  {
    id: 95,
    category: 'millWorkWall',
    img: d4
  },
  {
    id: 96,
    category: 'millWorkWall',
    img: d5
  },
  {
    id: 97,
    category: 'millWorkWall',
    img: d6
  },
  {
    id: 98,
    category: 'millWorkWall',
    img: d7
  },
  {
    id: 99,
    category: 'millWorkWall',
    img: d8
  },
  {
    id: 100,
    category: 'millWorkWall',
    img: d9
  },
  {
    id: 101,
    category: 'millWorkWall',
    img: d10
  },
  {
    id: 102,
    category: 'millWorkWall',
    img: d11
  },
  {
    id: 103,
    category: 'millWorkWall',
    img: d12
  },
  {
    id: 104,
    category: 'millWorkWall',
    img: d13
  },
  {
    id: 105,
    category: 'millWorkWall',
    img: d14
  },
  {
    id: 106,
    category: 'millWorkWall',
    img: d15
  },
  {
    id: 107,
    category: 'newDiskDrawerAndWardrobe',
    img: e
  },
  {
    id: 108,
    category: 'newDiskDrawerAndWardrobe',
    img: e1
  },
  {
    id: 109,
    category: 'newDiskDrawerAndWardrobe',
    img: e2
  },
  {
    id: 110,
    category: 'newDiskDrawerAndWardrobe',
    img: e3
  },
  {
    id: 111,
    category: 'newDiskDrawerAndWardrobe',
    img: e4
  },
  {
    id: 112,
    category: 'newDiskDrawerAndWardrobe',
    img: e5
  },
  {
    id: 113,
    category: 'outDoorSubstituteWood',
    img: g
  },
  {
    id: 114,
    category: 'outDoorSubstituteWood',
    img: g1
  },
  {
    id: 115,
    category: 'outDoorSubstituteWood',
    img: g2
  },
  {
    id: 116,
    category: 'outDoorSubstituteWood',
    img: g3
  },
  {
    id: 117,
    category: 'outDoorSubstituteWood',
    img: g4
  },
  {
    id: 118,
    category: 'outDoorSubstituteWood',
    img: g5
  },
  {
    id: 119,
    category: 'outDoorSubstituteWood',
    img: g6
  },
  {
    id: 120,
    category: 'outDoorSubstituteWood',
    img: g7
  },
  {
    id: 121,
    category: 'outDoorSubstituteWood',
    img: g8
  },
  {
    id: 122,
    category: 'outDoorSubstituteWood',
    img: g9
  },
  {
    id: 123,
    category: 'outDoorSubstituteWood',
    img: g10
  },
  {
    id: 124,
    category: 'GypsumBoard',
    img: c
  },
  {
    id: 125,
    category: 'GypsumBoard',
    img: c1
  },
  {
    id: 126,
    category: 'GypsumBoard',
    img: c2
  },
  {
    id: 127,
    category: 'GypsumBoard',
    img: c3
  },
  {
    id: 128,
    category: 'GypsumBoard',
    img: c4
  },
  {
    id: 129,
    category: 'GypsumBoard',
    img: c5
  },
  {
    id: 130,
    category: 'GypsumBoard',
    img: c6
  },
  {
    id: 131,
    category: 'GypsumBoard',
    img: c7
  },
  {
    id: 132,
    category: 'GypsumBoard',
    img: c8
  },
  {
    id: 133,
    category: 'GypsumBoard',
    img: c9
  },
  {
    id: 134,
    category: 'GypsumBoard',
    img: c10
  },
  {
    id: 135,
    category: 'GypsumBoard',
    img: c11
  },
  {
    id: 136,
    category: 'GypsumBoard',
    img: c12
  },
  {
    id: 137,
    category: 'GypsumBoard',
    img: c13
  },
  {
    id: 138,
    category: 'GypsumBoard',
    img: c14
  },
  {
    id: 139,
    category: 'GypsumBoard',
    img: c15
  },
  {
    id: 140,
    category: 'GypsumBoard',
    img: c16
  },
  {
    id: 141,
    category: 'GypsumBoard',
    img: c17
  },
  {
    id: 142,
    category: 'wallSoundInsulation',
    img: j
  },
  {
    id: 143,
    category: 'wallSoundInsulation',
    img: j1
  },
  {
    id: 144,
    category: 'wallSoundInsulation',
    img: j2
  },
  {
    id: 145,
    category: 'wallSoundInsulation',
    img: j3
  },
  {
    id: 146,
    category: 'wallSoundInsulation',
    img: j4
  },
  {
    id: 147,
    category: 'wallSoundInsulation',
    img: j5
  },
  {
    id: 148,
    category: 'wallSoundInsulation',
    img: j6
  },
  {
    id: 149,
    category: 'wallSoundInsulation',
    img: j7
  },
  {
    id: 150,
    category: 'wallSoundInsulation',
    img: j8
  },
  {
    id: 151,
    category: 'wallSoundInsulation',
    img: j9
  },
  {
    id: 152,
    category: 'wallSoundInsulation',
    img: j10
  },
  {
    id: 153,
    category: 'wallSoundInsulation',
    img: j11
  },
  {
    id: 154,
    category: 'wallSoundInsulation',
    img: j12
  },
  {
    id: 155,
    category: 'wallSoundInsulation',
    img: j13
  },
  {
    id: 156,
    category: 'wallSoundInsulation',
    img: j14
  },
  {
    id: 157,
    category: 'wallSoundInsulation',
    img: j15
  },
  {
    id: 158,
    category: 'wallSoundInsulation',
    img: j16
  },
  {
    id: 159,
    category: 'wallSoundInsulation',
    img: j17
  },
  {
    id: 160,
    category: 'inDoorSubstituteWood',
    img: k
  },
  {
    id: 161,
    category: 'inDoorSubstituteWood',
    img: k1
  },
  {
    id: 162,
    category: 'inDoorSubstituteWood',
    img: k2
  },
  {
    id: 163,
    category: 'inDoorSubstituteWood',
    img: k3
  },
  {
    id: 164,
    category: 'inDoorSubstituteWood',
    img: k4
  },
  {
    id: 165,
    category: 'inDoorSubstituteWood',
    img: k5
  },
  {
    id: 166,
    category: 'inDoorSubstituteWood',
    img: k6
  },
  {
    id: 167,
    category: 'marbleAlternative',
    img: l
  },
  {
    id: 168,
    category: 'marbleAlternative',
    img: l1
  },
  {
    id: 169,
    category: 'marbleAlternative',
    img: l2
  },
  {
    id: 170,
    category: 'marbleAlternative',
    img: l3
  },
  {
    id: 171,
    category: 'marbleAlternative',
    img: l4
  },
  {
    id: 172,
    category: 'bedAndDrawerBackground',
    img: m
  },
  {
    id: 173,
    category: 'bedAndDrawerBackground',
    img: m1
  },
  {
    id: 174,
    category: 'bedAndDrawerBackground',
    img: m2
  },
  {
    id: 175,
    category: 'bedAndDrawerBackground',
    img: m3
  },
  {
    id: 176,
    category: 'bedAndDrawerBackground',
    img: m4
  },
  {
    id: 177,
    category: 'bedAndDrawerBackground',
    img: m5
  },
  {
    id: 178,
    category: 'bedAndDrawerBackground',
    img: m6
  },
  {
    id: 179,
    category: 'bedAndDrawerBackground',
    img: m7
  },
  {
    id: 180,
    category: 'bedAndDrawerBackground',
    img: m8
  },
  {
    id: 181,
    category: 'bedAndDrawerBackground',
    img: m9
  },
  {
    id: 182,
    category: 'bedAndDrawerBackground',
    img: m10
  },
  {
    id: 183,
    category: 'bedAndDrawerBackground',
    img: m11
  },
  {
    id: 184,
    category: 'bedAndDrawerBackground',
    img: m12
  },
  {
    id: 185,
    category: 'bedAndDrawerBackground',
    img: m13
  },
  {
    id: 186,
    category: 'bedAndDrawerBackground',
    img: m14
  },
  {
    id: 187,
    category: 'bedAndDrawerBackground',
    img: m15
  },
  {
    id: 188,
    category: 'bedAndDrawerBackground',
    img: m16
  },
  {
    id: 189,
    category: 'bedAndDrawerBackground',
    img: m17
  },
  {
    id: 190,
    category: 'bedAndDrawerBackground',
    img: m18
  },
  {
    id: 191,
    category: 'bedAndDrawerBackground',
    img: m19
  },
  {
    id: 192,
    category: 'bedAndDrawerBackground',
    img: m20
  },
  {
    id: 193,
    category: 'bedAndDrawerBackground',
    img: m21
  },
]

export default all_Data