import{
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'

import RootLayout from './Layout/RootLayout';
import Home from './Pages/Home'
import Category from './Pages/Category';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout/>} >
      <Route index element={<Home />} />
      <Route path="الياف-ضوئية-روز" element={<Category category="opticalFiberForCeilings"/>} />
      <Route path="خلفيات-اسرة-ودواليب" element={<Category category="bedAndDrawerBackground"/>} />
      <Route path="خلفيات-تلفزيون" element={<Category category="TeleBackground"/>} />
      <Route path="مداخل-استقبال" element={<Category category="RecipEntrance"/>} />
      <Route path="ارضيات-خشبية-باركيه" element={<Category category="Barkieh"/>} />
      <Route path="ورق-جدران-ثلاثية-الابعاد" element={<Category category="3D_wallPaper"/>} />
      <Route path="براويز-فوم" element={<Category category="millWorkWall"/>} />
      <Route path="خزائن-ودواليب" element={<Category category="newDiskDrawerAndWardrobe"/>} />
      <Route path="بديل-الخشب-خارجي" element={<Category category="outDoorSubstituteWood"/>} />
      <Route path="جبس-بورد" element={<Category category="GypsumBoard"/>} />
      <Route path="عوازل-اصوات-جدران-ابواب-اسقف" element={<Category category="wallSoundInsulation"/>} />
      <Route path="بديل-الخشب-داخلي" element={<Category category="inDoorSubstituteWood"/>} />
      <Route path="بديل-الرخام-مرايا" element={<Category category="marbleAlternative"/>} />
    </Route>
  )
)
function App() {
 

  return (
      <div>
       <RouterProvider router={router}  />
      </div>
  )
}

export default App
