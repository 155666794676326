import { FaWhatsapp } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <div className='sticky  bg-transparent w-20   bottom-[30%] mr-[77%] md:mr-[90%] z-50 '>
      <div className="flex flex-col justify-center items-end ml-[6%]">
        <div className="bg-slate-800 p-1 rounded-full rounded-bl-3xl">
          <a href="https://wa.me/+9660507276269" rel="noreferrer" target="_blank">
          <FaWhatsapp color="#25D366" size={50}/>
          </a>
        </div>
        <div className="bg-lightBlue p-1 mt-4 rounded-full rounded-bl-3xl">
          <a href="tel: +9660507276269" rel="noreferrer" target="_blank">
          <IoIosCall color="#fff" size={50}/>
          </a>
        </div>
        <div className="bg-[#E1306C] p-1 mt-4 rounded-full rounded-bl-3xl">
          <a href="https://www.instagram.com/abo.salih_0_?igsh=YzljYTk1ODg3Zg==" rel="noreferrer" target="_blank">
          <FaInstagram  color="#fff" size={50}/>
          </a>
        </div>
        
      </div>
    </div>
  )
}

export default Contact
