import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import a from '../../assets/Hero/a.jpg'
import b from '../../assets/Hero/b.jpg'
import c from '../../assets/Hero/c.jpg'
import d from '../../assets/Hero/d.jpg'
import e from '../../assets/Hero/e.webp'
import f from '../../assets/Hero/f.webp'


const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    cssEase: "linear"
  };
  return (
    <div className="relative h-[70vh] rounded-lg object-cover overflow-hidden w-full ">
      <Slider {...settings}>
        
        <img className="object-cover h-[89vh] w-full opacity-90" src={a} alt="" />
        <img className="object-cover h-[89vh] w-full opacity-90" src={b} alt="" />
        <img className="object-cover h-[89vh] w-full opacity-90" src={f} alt="" />
        <img className="object-cover h-[89vh] w-full opacity-90" src={c} alt="" />
        <img className="object-cover h-[89vh] w-full opacity-90" src={d} alt="" />         
        <img className="object-cover h-[89vh] w-full opacity-90" src={e} alt="" />
        <img className="object-cover h-[89vh] w-full opacity-90" src={f} alt="" />
      </Slider>
      <div 
        
        className="absolute top-[40%] w-full rounded-lg bg-slate-500 bg-opacity-40 text-white flex items-center justify-center flex-col ">
        <h1 className="lg:text-6xl text-4xl py-4 font-bold ">
          ديكورات الشرقية
        </h1>
        <p className="text-center lg:text-xl font-bold">
          مرحبا بكم في موقع شركة ديكورات الشرقية  حيث نعرض فيه خدماتنا 
          </p>
        <p className="text-center lg:text-xl font-bold p-4">
          الدمام | الضهران | العزيزية | القطيف | الجبيل       </p>
        <p className="text-center lg:text-2xl font-bold text-xl p-4">للتواصل: 0507276269</p>
      </div>
    </div>
  )
}

export default Hero
