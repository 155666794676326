import {useSpring, animated} from "react-spring"
import {motion} from 'framer-motion'
const Numbers = () => {
  function NumberFunc ({n}) {
    const {number} = useSpring({
      from:{ number: 0},
      number: n,
      delay: 200,
      config:{mass: 1, tension: 20, friction: 10  }
    })
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
  }
  return (
    <motion.div className="flex flex-col justify-center items-center"
      initial={{x: 50, opacity: 0}}
      whileInView={{x: 0, opacity: 1}}
      viewport={{once: true}}
      transition={{delay: 0.7, 
        y: {
          type: 'spring', stiffness: 60
        },
        opacity: {duration: 1},
        ease: 'easeIn',
        duration: 0.7,
        
      }}
    >
      <h1 className="p-2 text-4xl font-semibold">ديكورات الشرقية</h1>
      <p className=" p-2 text-xl font-medium">الارقام تتحدث عن إنجازاتنا</p>
    <div className="grid py-10 lg:grid-cols-3 md:grid-cols-2 place-items-center gap-10">
      <div className="flex flex-col justify-center bg-primary w-72 h-44 rounded-tr-[50%]">
        <div className="flex justify-center items-center">
        <h1 className="text-center text-white p-2 text-4xl">
          <NumberFunc n={254} />
        </h1>
        <span className="text-center text-white text-4xl">+</span>
        </div>
        <p className="text-center text-white text-lg">
          أعمال ديكور منفذة
        </p>
        <hr className="w-[80%] self-center mt-2" />
      </div>
      <div className="flex flex-col justify-center bg-primary w-72 h-44 rounded-tr-[50%]">
        <div className="flex justify-center items-center">
 
        <h1 className="text-center text-white p-2 text-4xl">
          <NumberFunc n={254} />
        </h1>
        <span className="text-center text-white text-4xl">+</span>
        </div>
        <p className="text-center text-white text-lg">
          أعمال ديكور منفذة
        </p>
        <hr className="w-[80%] self-center mt-2" />
      </div>
      <div className="flex flex-col justify-center bg-primary w-72 h-44 rounded-tr-[50%]">
        <div className="flex justify-center items-center">
 
        <h1 className="text-center text-white p-2 text-4xl">
          <NumberFunc n={254} />
        </h1>
        <span className="text-center text-white text-4xl">+</span>
        </div>
        <p className="text-center text-white text-lg">
          أعمال ديكور منفذة
        </p>
        <hr className="w-[80%] self-center mt-2" />
      </div>
    </div>
    </motion.div>
  )
}

export default Numbers
