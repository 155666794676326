import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col bg-slate-900 w-full items-center justify-center text-white">
        <div >
          <h1 className="text-2xl pt-4 font-lg">روابط هامة</h1>
        </div>
        <div className="flex flex-wrap gap-x-8 py-6 items-center justify-center text-lg">
          <Link to='الياف-ضوئية-روز'>
           <p className="hover:text-lightBlue">ألياف ضوئية (روز)</p>
          </Link>
          <Link to='خلفيات-تلفزيون'>
            <p className="hover:text-lightBlue">خلفيات تلفزيون</p>
          </Link>
          <Link to='مداخل-استقبال'>
            <p className="hover:text-lightBlue">مداخل الاستقبال</p>
          </Link>
          <Link to='ارضيات-خشبية-باركيه'>
            <p className="hover:text-lightBlue">الارضيات الخشبية</p>
          </Link>
          <Link to='ورق-جدران-ثلاثية-الابعاد'>
            <p className="hover:text-lightBlue">ورق جدران 3D</p>
          </Link>
          <Link to='براويز-فوم'>
            <p className="hover:text-lightBlue">براويز فوم</p>
          </Link>
          <Link to='خزائن-ودواليب'>
            <p className="hover:text-lightBlue"> خزائن ودواليب</p>
          </Link>
          <Link to='بديل-الخشب-خارجي'>
            <p className="hover:text-lightBlue">بديل الخشب (خارجي)</p>
          </Link>
          <Link to='جبس-بورد'>
            <p className="hover:text-lightBlue">جبس بورد</p>
          </Link>
          <Link to='بديل-الخشب-داخلي'>
            <p className="hover:text-lightBlue">بديل الخشب (داخلي) </p>
          </Link>
          <Link to='بديل-الرخام-مرايا'>
            <p className="hover:text-lightBlue"> بديل الرخام مرايا</p>
          </Link>
          <Link to='عوازل-اصوات-جدران-ابواب-اسقف'>
            <p className="hover:text-lightBlue">عوازل اصوات جدران ابواب اسقف</p>
          </Link>
        </div>
      </div>
      <div className="flex flex-1 text-xl font-medium p-2">
        <p > الحقوق محفوظة ©     </p>
        <Link to='/' className="border-b text-secondary border-secondary"> ديكورات الشرقية </Link>
      </div>
      <div className="text-xl font-medium pt-2 pb-7">
      </div>
    </div>
  )
}

export default Footer
