import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import Logo from "../../assets/Logo.png";
import { RiMenu2Line } from "react-icons/ri";
import { RiCloseLine } from "react-icons/ri";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const menu = (
    <>
      <div className="lg:hidden block absolute top-0  w-[90%] h-full border-2  left-0 transition ease-in">
        <ul className="text-center text-2xl bg-opacity-95 bg-white  p-20 ">
          <a spy={true} smooth={true} href="/">
            <li className="my-4 py-1 border-b">الرئيسية</li>
          </a>
          <li>
            <Menu as="div" className="relative inline-block border-b w-full ">
              <div >
                <Menu.Button className="inline-flex text-2xl w-full justify-center gap-x-1.5  hover:bg-gray-50">
                  <ChevronDownIcon
                    className="-ml-1 h-5 w-5 text-gray-400 mt-1"
                    aria-hidden="true"
                  />
                  أعمالنا
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="   mt-2 w-56 origin-top -right-8 rounded-md  focus:outline-none">
                  <div className="py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="الياف-ضوئية-روز"
                          className={classNames(
                            active
                              ? "border-b bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          ألياف ضوئية (روز)
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="خلفيات-تلفزيون"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          خلفيات تلفزيون
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="مداخل-استقبال"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          مداخل الاستقبال
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="ارضيات-خشبية-باركيه"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          الارضيات الخشبية
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="ورق-جدران-ثلاثية-الابعاد"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          ورق جدران 3D
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="براويز-فوم"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          براويز فوم
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="خزائن-ودواليب"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          خزائن ودواليب
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="بديل-الخشب-خارجي"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          بديل الخشب (خارجي)
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="جبس-بورد"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          جبس بورد
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="عوازل-اصوات-جدران-ابواب-اسقف"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          عوازل اصوات جدران ابواب اسقف
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="بديل-الخشب-داخلي"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          بديل الخشب (داخلي)
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="بديل-الرخام-مرايا"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-lg"
                          )}
                        >
                          بديل الرخام مرايا
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
          <a spy={true} smooth={true} href="/">
            <li className="my-4 py-1 border-b active:bg-gray-100 text-gray-900">
              عنا
            </li>
          </a>
          <p>  </p>
          <a spy={true} smooth={true} href="tel: 779345654">
             <button className="bg-primary p-2 ring-1 rounded-md text-white hover:scale-110 transition hover:bg-secondary">اتصل بنا</button> 
          </a>
        </ul>
      </div>
    </>
  );
  return (
    <div className="flex flex-col w-full">
      <nav className="sticky top-0 bg-white shadow-lg shadow-primary flex justify-between z-50 lg:py-5 px-2 py-4">
        <div className="flex items-center flex-1">
          <div className="w-28 lg:mx-24">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>
          <div className=" lg:flex md:flex lg: flex-1 items-center justify-end font-semibold hidden">
            <ul className="flex gap-12 mx-24 text-[24px]  ">
              <a spy={true} smooth={true} href="/">
                <li className="hover:text-lightBlue focus:text-lightBlue transition hover:border-b-2 border-lightBlue">
                  الرئيسية
                </li>
              </a>
              <Menu as="div" className="relative inline-block ">
                <div>
                  <Menu.Button className="inline-flex text-2xl w-full justify-center gap-x-1.5  hover:bg-gray-50  hover:border-b-2 hover:border-b-lightBlue hover:text-lightBlue ">
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400 mt-1"
                      aria-hidden="true"
                    />
                    أعمالنا
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className=" absolute bg-white mt-2 w-56 origin-top rounded-md  focus:outline-none">
                    <div className="py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="الياف-ضوئية-روز"
                            className={classNames(
                              active
                                ? "border-b bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            ألياف ضوئية (روز)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="خلفيات-تلفزيون"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            خلفيات تلفزيون
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="مداخل-استقبال"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            مداخل الاستقبال
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="ارضيات-خشبية-باركيه"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            الارضيات الخشبية
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="ورق-جدران-ثلاثية-الابعاد"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            ورق جدران 3D
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="براويز-فوم"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            براويز فوم
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="خزائن-ودواليب"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            خزائن ودواليب
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="بديل-الخشب-خارجي"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            بديل الخشب (خارجي)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="جبس-بورد"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            جبس بورد
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="عوازل-اصوات-جدران-ابواب-اسقف"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            عوازل اصوات جدران ابواب اسقف
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="بديل-الخشب-داخلي"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            بديل الخشب (داخلي)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="بديل-الرخام-مرايا"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-lg"
                            )}
                          >
                            بديل الرخام مرايا
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <a spy={true} smooth={true} href="/">
                <li className="hover:text-lightBlue focus:text-lightBlue transition hover:border-b-2 border-lightBlue">
                  عنا
                </li>
              </a>
              <a spy={true} smooth={true} href="tel: 779345654">
              <button className="bg-primary p-2 ring-1 rounded-md text-white hover:scale-110 transition hover:bg-secondary">اتصل بنا</button>
              </a>
            </ul>
          </div>
          <div>
            {click && menu}
            <button
              className="block md:hidden transition ease-in duration-700 left-4 absolute top-6"
              onClick={handleClick}
            >
              {click ? (
                <RiCloseLine className="w-10 h-10 hover:font-primary text-lightBlue hover:text-secondary" />
              ) : (
                <RiMenu2Line className="w-10 h-10 text-lightBlue hover:text-secondary" />
              )}
            </button>
          </div>
        </div>
      </nav>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Navbar;
