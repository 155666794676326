const Banner = [
  {
    banner: 'bedAndDrawerBackground',
    category: 'خلفيات-اسرة-ودواليب',
    title: 'خلفيات اسرة ودواليب',
    description: 'هل تبحث عن طريقة مثالية لإضفاء لمسة من الأناقة والراحة على منزلك؟ نقدم لك مجموعة مذهلة من خلفيات الأسرة ودواليب متميزة التي تضيف لمسة من الفخامة والتناسق إلى أي غرفة. تتميز تصاميمنا بجودة عالية وحرفية متقنة، مما يضمن لك تجربة استخدام مريحة ودائمة.'
  },
  {
    banner:'opticalFiberForCeilings',
    category :  'الياف-ضوئية-روز',
    title: 'الألياف الضوئية (روز)',
    description: ' ديكور الألياف الضوئية هو تقنية تحول المساحات الداخلية والخارجية إلى لوحة فنية متألقة بوساطة الضوء والألوان. تتميز هذه التقنية بقدرتها على إضفاء السحر والجمال على أي بيئة، سواء كانت منازل، مكاتب، مطاعم، فنادق أو أي مكان آخر.'
  },
  {
    banner:'TeleBackground',
    category: 'خلفيات-تلفزيون',
    title: 'خلفيات التلفزيون',
    description: 'عنصر مهم في تصميم الغرفة وتجربة المشاهدة المريحة. تلعب الخلفيات دورًا حيويًا في تعزيز جمالية المكان وإضافة لمسة من الشخصية إلى الديكور العام للمنزل. يتم اختيار خلفيات التلفزيون بعناية لتكمل أسلوب الديكور الموجود في الغرفة '
  },
  {
    banner:'RecipEntrance',
    category: 'مداخل-استقبال',
    title: 'مداخل الاستقبال',
    description: 'يعتبر نقطة البداية الأولى التي يلاحظها الزائرون عند دخولهم لأي منزل أو مكتب، ولذلك فإن تصميمها يلعب دوراً حيوياً في إنشاء انطباعٍ إيجابي وترحيبي. يهدف ديكور مداخل الاستقبال إلى خلق جو من الفخامة والدفء والاستقبال الودي، مما يعكس شخصية المضيفين ويخلق تجربة استقبال مريحة للضيوف.'
  },
  {
    banner:'Barkieh',
    category: 'ارضيات-خشبية-باركيه',
    title: 'الارضيات الخشبية',
    description: 'أحد أشكال التصميم الداخلي الشهيرة والتي تضفي لمسة من الفخامة والدفء على أي مساحة داخلية. تتميز الأرضيات الخشبية بجماليتها الطبيعية وتنوعها في الألوان والأنماط، مما يجعلها تناسب مختلف أنماط الديكور وتتناغم مع تصاميم الغرف المختلفة.'
  },
  {
    banner:'3D_wallPaper',
    category: 'ورق-جدران-ثلاثية-الابعاد',
    title: 'ورف جدران 3D',
    description: 'ورق الجدران ثلاثي الأبعاد (3D) هو تطور مبتكر في عالم الديكور الداخلي، حيث يوفر تأثيرًا بصريًا مذهلاً ويضفي عمقًا وحيوية على الجدران. يتميز ورق الجدران ثلاثي الأبعاد بتصاميمه الواقعية والملمسية التي تخلق إحساسًا بالتفاعل والتجسيد.'
  },
  {
    banner:'millWorkWall',
    category: 'براويز-فوم',
    title: 'براويز فوم',
    description: 'خيار شائع ومبتكر في عالم الديكور الداخلي، حيث تقدم تصميمًا أنيقًا وعصريًا لعرض الصور واللوحات. تتميز براويز الفوم بخفة وزنها وسهولة تشكيلها، حيث يتم صنعها عادةً من مواد فومية خفيفة الوزن تتيح للمصممين إمكانية الابتكار في التصميم والشكل.'
  },
  {
    banner:'newDiskDrawerAndWardrobe',
    category: 'خزائن-ودواليب',
    title: 'خزائن ودواليب',
    description: 'ديكور الخزائن والدواليب يلعب دورًا أساسيًا في تنظيم وترتيب المساحات الداخلية للمنازل والمكاتب والمحلات التجارية. تأتي الخزائن والدواليب بمجموعة متنوعة من التصاميم والأحجام والأنماط لتلبية احتياجات التخزين والديكور المختلفة.'
  },
  {
    banner:'outDoorSubstituteWood',
    category: 'بديل-الخشب-خارجي',
    title: 'بديل الخشب (خارجي)',
    description: 'يُستخدم لتجديد وتحسين المظهر الخارجي للمنازل والمباني والفضاءات العامة. يُعتبر الخشب البديل، مثل البوليمرات المركبة والألياف الزجاجية والبلاستيك المقوى والخرسانة الملونة، بديلاً عن الخشب التقليدي لأنه يوفر مظهرًا جماليًا مشابهًا ومقاومة أعلى للعوامل الجوية والتآكل.'
  },
  {
    banner:'GypsumBoard',
    category: 'جبس-بورد',
    title: 'جبس بورد',
    description: 'يتميز الجبس بورد بالمرونة والتنوع في التصميم، حيث يمكن استخدامه لإنشاء أشكال مختلفة، مثل الأقواس والأعمدة والزخارف الجدارية المعقدة. بالإضافة إلى ذلك، يتيح الجبس بورد للمصممين والمالكين تطبيق الديكورات المميزة والتفاصيل الفنية بسهولة، سواء كانت تقليدية أو عصرية.'
  },
  {
    banner:'wallSoundInsulation',
    category: 'عوازل-اصوات-جدران-ابواب-اسقف',
    title: 'عوازل الصوت جدران ابواب اسقف',
    description: 'عوازل الصوت للجدران والأبواب والأسقف يُعتبر جزءًا مهمًا من تصميم الديكور الداخلي لأي مكان يهدف إلى تحقيق الهدوء والراحة الصوتية. تُستخدم هذه العوازل لتقليل انتقال الضوضاء والصوت من الخارج إلى الداخل أو بين الغرف داخل نفس المبنى.'
  },
  {
    banner:'inDoorSubstituteWood',
    category: 'بديل-الخشب-داخلي',
    title: 'بديل الخشب داخلي',
    description: '    بديل الخشب داخلي يشير إلى المواد التي تستخدم كبديل عن الخشب التقليدي في التطبيقات الداخلية. يتم اختيار بديل الخشب لعدة أسباب منها المتانة، والمظهر، والاستدامة البيئية. من بين الخيارات الشائعة لبديل الخشب داخلي'
  },
  {
    banner:'marbleAlternative',
    category: 'بديل-الرخام-مرايا',
    title: 'بديل الرخام مرايا',
    description: '"اكتشف جمال الرخام بتكلفة أقل مع بديل الرخام الذكي! تتيح لك تقنياتنا المتطورة الاستمتاع بالمظهر الفخم والأناقة العصرية التي يوفرها الرخام التقليدي، دون الحاجة إلى الاستثمار الضخم. بديل الرخام يأتي بمجموعة متنوعة من الخيارات المبتكرة مثل الجرانيت المتين، والكوارتز ذو المظهر الراقي، والألواح الخزفية الفاخرة'
  },
]

export default Banner