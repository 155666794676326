import { FcIdea } from "react-icons/fc";
import { FaScaleBalanced } from "react-icons/fa6";
import { RiCustomerService2Line } from "react-icons/ri";
import {motion} from 'framer-motion'

const WhyUs = () => {
  return (
    <motion.div 
    initial={{y: 50, opacity: 0}}
    whileInView={{y: 0, opacity: 1}}
    viewport={{once: true}}
    transition={{delay: 0.7, 
      y: {
        type: 'spring', stiffness: 60
      },
      opacity: {duration: 1},
      ease: 'easeIn',
      duration: 0.5,
    }}
    >
      <div className='flex flex-col items-center justify-center '>
        <h1 className='font-bold text-4xl'>ما نتميز به</h1>
        <span className='h-4 w-4 mt-1 bg-gradient-to-tr from-cyan-500 to-purple-700 rounded-full'></span>
      </div>
      <modiv className=' py-10 grid grid-cols-1 md:grid-cols-2 justify-items-center  lg:grid-cols-3 gap-4 lg:gap-8'>
        <div className="h-48 w-72 flex flex-col items-center justify-center shadow-lg shadow-slate-400 rounded-lg ">
          <span><FcIdea size={30} /></span>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-xl font-semibold p-2 ">الإبداع والابتكار</h1>
            <p className="text-center font-medium">نبحث عن فرص لإضافة لمسات فنية أو عناصر غير تقليدية تميز عملنا عن الآخرين. باستخدم مواد جديدة أو تقنيات مبتكرة لتحقيق تأثيرات مثيرة للاهتمام.
            </p>
          </div>
        </div>
        <modiv className="h-48 w-72 flex flex-col items-center justify-center shadow-lg shadow-slate-400 rounded-lg ">
          <span><FaScaleBalanced size={30} color="#048ED6" /></span>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-xl font-semibold p-2 "> التوازن والتناغم </h1>
            <p className="text-center font-medium">نسعَ إلى تحقيق توازن بين العناصر المختلفة في التصميم، مثل الألوان والأشكال والمواد. </p>
          </div>
        </modiv>
        <modiv className="h-48 w-72 flex flex-col items-center justify-center shadow-lg shadow-slate-400 rounded-lg ">
          <span><RiCustomerService2Line  size={30} color="#8a00c2"/></span>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-xl font-semibold p-2 text-center">التفاعل مع العملاء بشكل فعّال</h1>
            <p className="text-center font-medium">
            نستمع إلى ملاحظات العملاء وتفضيلاتهم واضعين في اعتبارنا تغييرات بناءً على ملاحظاتهم.
            </p>
          </div>
        </modiv>
      </modiv>
    </motion.div>
  )
}

export default WhyUs
