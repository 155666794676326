import Footer from '../Components/Footer/Footer';
import Hero from '../Components/Hero/Hero';
import Numbers from '../Components/Numbers/Numbers';
import Services from '../Components/Services/Services';
import WhyUs from '../Components/WhyUs/WhyUs';
const Home = () => {
  return (
    <div className="overflow-hidden">
      <Hero />
      <Services />
      <WhyUs />
      <Numbers />

    </div>
  )
}

export default Home
