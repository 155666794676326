/* eslint-disable array-callback-return */
import all_Data from "../Data/all_images";
import Item from "../Components/Item/Item";
import Banner from "../Data/Banner";
import FirstItem from "../Components/FirstItem/FirstItem";
import Scroll from "../Components/scroll";

const Category = ({ category }) => {
  return (
    <div>
      <Scroll />
      <div>
        {Banner &&
          Banner.map((item) => {
            if (category === item.banner) {
              return (
                <FirstItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                />
              );
            }
          })}
        <div className="'w-full max-w-5xl p-5 pb-10 mx-auto gap-5 lg:columns-3 md:columns-2 columns-1 space-y-5">
          {all_Data &&
            all_Data.map((item) => {
              if (category === item.category) {
                return <Item key={item.id} img={item.img} />;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Category;
